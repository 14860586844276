import {createRouter, createWebHashHistory} from "vue-router";


const Home = () => import('../components/HelloWorld.vue');
const editor = () => import('../views/editor.vue');

const routes = [
    {path: '/', component: Home},
    {path: '/editor', component: editor},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;