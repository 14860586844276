import { createApp } from 'vue'
import App from './App.vue'
import './samples/node-api'
import router from './router/router'
const app = createApp(App)
app.use(router)
// app.use(function (req, res, next) {
//     res.setHeader(
//         'Content-Security-Policy-Report-Only',
//         "default-src 'self'; font-src 'self'; img-src 'self'; script-src 'self'; style-src 'self'; frame-src 'self'"
//     );
//     next();
// });
app.mount('#app').$nextTick(window.removeLoading)

